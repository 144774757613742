import React from 'react';
import { useEffect, useState } from 'react';
import PageRoute from './config/routes';
import './css/main.css'
// import '.css/main.css';

function App() {
  return (
    <main>
      <PageRoute/>
    </main>
  );
}

export default App;
